








import Vue from 'vue'
import Component from 'vue-class-component'
import PivotTable from '@/modules/genprox/components/pivot-table/PivotTable.vue';
import PivotReports from '@/modules/genprox/components/pivot-table/PivotReports.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import moment from 'moment'
import { Watch } from 'vue-property-decorator';

@Component({
  components: { SygniLoader, PivotTable, PivotReports }
})
export default class PivotContainer extends Vue {
  isLoading: boolean = false
  tableData: any = null
  selectedReport: string | null = null
  reports: any[] = [];

  tableItems: any = [
    {
      id: 1,
      name: 'Bank Transactions',
      action: 'bank-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 2,
      name: 'P&L Transactions',
      action: 'p-l-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 3,
      name: 'Portfolio & Capital',
      action: 'portfolio-capital',
      type: 'dateTo',
      period: moment().utc().format('YYYY')
    },
    {
      id: 4,
      name: 'All entries Transactions',
      action: 'all-entries-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 5,
      name: 'Receivables & Payables',
      action: 'receivables-payables',
    },
    {
      id: 6,
      name: 'Fundraising',
      action: 'fundraising-analytics-debt',
    },
    {
      id: 7,
      name: 'Brokers',
      action: 'broker',
    },
    {
      id: 8,
      name: 'Interest',
      action: 'interests'
    },
    {
      id: 9,
      name: 'Fundraising Dashboard',
      action: 'fundraising-dashboard'
    }
  ]

  get reportList() {
    return this.reports?.length > 0 ? this.reports : this.tableItems
  }

  get pivotSchema() {
    let columns: any = []

    switch (this.selectedReport) {
      case 'P&L Transactions':
      case 'All entries Transactions':
        columns = [
          {
            id: 'account',
            title: 'Account',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountType',
            title: 'Account Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel1',
            title: 'Account lvl 1',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel2',
            title: 'Account lvl 2',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel3',
            title: 'Account lvl 3',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel4',
            title: 'Account lvl 4',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel5',
            title: 'Account lvl 5',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel1Number',
            title: 'Account lvl 1 No.',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel2Number',
            title: 'Account lvl 2 No.',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel3Number',
            title: 'Account lvl 3 No.',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel4Number',
            title: 'Account lvl 4 No.',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel5Number',
            title: 'Account lvl 5 No.',
            colType: 'text',
            show: true,
          },
          {
            id: 'finalAccount',
            title: 'Final Account',
            colType: 'text',
            show: true,
          },
          {
            id: 'finalAccountNumber',
            title: 'Final Account Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'ccNumber',
            title: 'CC No.',
            colType: 'text',
            show: true,
          },
          {
            id: 'ccName',
            title: 'CC Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountingDate',
            title: 'Accounting Date',
            colType: 'datetime',
            opened: false,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'accountingDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'accountingDateYear',
                    title: 'Accounting Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'accountingDateMonth',
                    title: 'Accounting Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'accountingDateYear',
            title: 'Accounting Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'accountingDateMonth',
            title: 'Accounting Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'issueDate',
            title: 'Issue Date',
            colType: 'datetime',
            opened: false,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'issueDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'issueDateYear',
                    title: 'Issue Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'issueDateMonth',
                    title: 'Issue Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'issueDateYear',
            title: 'Issue Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'issueDateMonth',
            title: 'Issue Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'operationDate',
            title: 'Operation Date',
            colType: 'datetime',
            opened: false,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'operationDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'operationDateYear',
                    title: 'Operation Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'operationDateMonth',
                    title: 'Operation Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'operationDateYear',
            title: 'Operation Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'operationDateMonth',
            title: 'Operation Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'documentNumber',
            title: 'Document Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'documentDescription',
            title: 'Document Description',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountingId',
            title: 'Accounting ID',
            colType: 'text',
            show: true,
          },
          {
            id: 'journal',
            title: 'Journal',
            colType: 'text',
            show: true,
          },
          {
            id: 'counterpartyName',
            title: 'Counterparty Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'turnoversDt',
            title: 'Turnovers Dt',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'turnoversCt',
            title: 'Turnovers Ct',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'approvedBuffer',
            title: 'Approved/Buffer',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountingPeriod',
            title: 'Accounting Period',
            colType: 'text',
            show: true,
          },
        ];
        break;
      case 'Portfolio & Capital':
        columns = [
          {
            id: 'finalAccount',
            title: 'Final Account',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel1',
            title: 'Account lvl 1',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel2',
            title: 'Account lvl 2',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel3',
            title: 'Account lvl 3',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel4',
            title: 'Account lvl 4',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel5',
            title: 'Account lvl 5',
            colType: 'text',
            show: true,
          },
          {
            id: 'amount',
            title: 'Amount',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'type',
            title: 'Type',
            colType: 'text',
            show: true,
          },
        ]
        break;
      case 'Receivables & Payables':
        columns = [
          {
            id: 'documentNumber',
            title: 'Document Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'documentDate',
            title: 'Document Date',
            colType: 'datetime',
            opened: false,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'documentDate',
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'documentDateYear',
                    title: 'Document Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'documentDateMonth',
                    title: 'Document Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'documentDateYear',
            title: 'Document Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'documentDateMonth',
            title: 'Document Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentDate',
            title: 'Payment Date',
            colType: 'datetime',
            opened: false,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'paymentDate',
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'paymentDateYear',
                    title: 'Payment Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'paymentDateMonth',
                    title: 'Payment Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'paymentDateYear',
            title: 'Payment Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentDateMonth',
            title: 'Payment Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'amountCcy',
            title: 'Amount Ccy',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: []
            },
            show: true,
          },
          {
            id: 'amountPln',
            title: 'Amount PLN',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'numberOfDaysFromIssueDateToDueDate',
            title: 'Number of days from Issue Date to Due Date',
            colType: 'number',
            format: {
              value: false, // thousandSeparator
              options: [],
            },
            show: true,
          },
          {
            id: 'numberOfDaysOverdue',
            title: 'Number of days Overdue',
            colType: 'number',
            format: {
              value: false, // thousandSeparator
              options: [],
            },
            show: true,
          },
          {
            id: 'counterpartyName',
            title: 'Counterparty Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'receivableCcy',
            title: 'Receivable Ccy',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'receivablePln',
            title: 'Receivable PLN',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'liabilityCcy',
            title: 'Liability Ccy',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'liabilityPln',
            title: 'Liability PLN',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'currency',
            title: 'Currency',
            colType: 'text',
            show: true,
          },
          {
            id: 'description',
            title: 'Description',
            colType: 'text',
            show: true,
          },
          {
            id: 'settlementDate',
            title: 'Settlement Date',
            colType: 'datetime',
            opened: false,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'settlementDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'settlementDateYear',
                    title: 'Settlement Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'settlementDateMonth',
                    title: 'Settlement Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'settlementDateYear',
            title: 'Settlement Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'settlementDateMonth',
            title: 'Settlement Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'settlementAmountCcy',
            title: 'Settlement Amount Ccy',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'settlementAmountPln',
            title: 'Settlement Amount PLN',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'settlementStatus',
            title: 'Settlement Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'type',
            title: 'Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'overdueInterval',
            title: 'Overdue Interval',
            colType: 'text',
            show: true,
          },
          {
            id: 'receivableLiability',
            title: 'Receivable/Liability',
            colType: 'text',
            show: true,
          },
        ]
        break;
      case 'Fundraising':
        columns = [
          {
            id: 'company',
            title: 'Company',
            colType: 'text',
            show: true,
          },
          {
            id: 'investor',
            title: 'Investor',
            colType: 'text',
            show: true,
          },
          {
            id: 'investorStatus',
            title: 'Investor Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'code',
            title: 'Code',
            colType: 'text',
            show: true,
          },
          {
            id: 'type',
            title: 'Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'eSignatureStatus',
            title: 'E-signature Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'signedStatus',
            title: 'Signed Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'productStatus',
            title: 'Product Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'investmentValue',
            title: 'Investment Value',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'currency',
            title: 'Currency',
            colType: 'text',
            show: true,
          },
          {
            id: 'exchangeRate',
            title: 'Exchange Rate',
            colType: 'number',
            format: {
              value: 'advancedFloat',
              options: [],
            },
            show: true,
          },
          {
            id: 'exchangeDate',
            title: 'Exchange Date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'exchangeDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'exchangeDateYear',
                    title: 'Exchange Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'exchangeDateMonth',
                    title: 'Exchange Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'exchangeDateYear',
            title: 'Exchange Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'exchangeDateMonth',
            title: 'Exchange Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'campaignManager',
            title: 'Campaign manager',
            colType: 'text',
            show: true,
          },
          {
            id: 'campaignName',
            title: 'Campaign name',
            colType: 'text',
            show: true,
          },
          {
            id: 'campaignValue',
            title: 'Campaign value',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'agreementDate',
            title: 'Agreement date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'agreementDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'agreementDateYear',
                    title: 'Agreement date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'agreementDateMonth',
                    title: 'Agreement date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'agreementDateYear',
            title: 'Agreement date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'agreementDateMonth',
            title: 'Agreement date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'endDate',
            title: 'End date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'endDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'endDateYear',
                    title: 'End date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'endDateMonth',
                    title: 'End date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'endDateYear',
            title: 'End date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'endDateMonth',
            title: 'End date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentValue',
            title: 'Payment value',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'paymentValueAll',
            title: 'Payment value - all',
            colType: 'array',
            format: 'number',
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentDate',
            title: 'Payment date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'paymentDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'paymentDateYear',
                    title: 'Payment date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'paymentDateMonth',
                    title: 'Payment date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'paymentDateYear',
            title: 'Payment date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentDateMonth',
            title: 'Payment date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentDateAll',
            title: 'Payment date - all',
            colType: 'array',
            format: 'datetime',
            show: true,
            readOnly: true,
          },
          {
            id: 'clatTax',
            title: 'Clat tax',
            colType: 'boolean',
            show: true,
          },
          {
            id: 'capitalGainsTax',
            title: 'Capital gains tax',
            colType: 'boolean',
            show: true,
          },
          {
            id: 'owner',
            title: 'Owner',
            colType: 'text',
            show: true,
          },
          {
            id: 'network',
            title: 'Network',
            colType: 'text',
            show: true,
          },
          {
            id: 'source',
            title: 'Source',
            colType: 'text',
            show: true,
          },
          {
            id: 'duration',
            title: 'Duration',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
          },
          {
            id: 'durationPeriod',
            title: 'Duration - period',
            colType: 'text',
            show: true,
          },
          {
            id: 'description',
            title: 'Description',
            colType: 'text',
            show: true,
          },
          {
            id: 'interestPaidWithin',
            title: 'Interest paid within',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
          },
          {
            id: 'interest',
            title: 'Interest',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'interestPaymentPeriod',
            title: 'Interest payment (period)',
            colType: 'text',
            show: true,
          },
          {
            id: 'margin',
            title: 'Margin',
            colType: 'text',
            show: true,
          },
          {
            id: 'marginPayment',
            title: 'Margin Payment',
            colType: 'text',
            show: true,
          },
          {
            id: 'commission',
            title: 'Commission',
            colType: 'text',
            show: true,
          },
          {
            id: 'commissionPayment',
            title: 'Commission Payment',
            colType: 'text',
            show: true,
          },
          {
            id: 'debtor',
            title: 'Debtor',
            colType: 'text',
            show: true,
          },
          {
            id: 'initialPaymentName',
            title: 'Initial payment - name',
            colType: 'text',
            show: true,
          },
          {
            id: 'initialPayment',
            title: 'Initial payment',
            colType: 'text',
            show: true,
          },
          {
            id: 'initialPaymentSwift',
            title: 'Initial payment - swift',
            colType: 'text',
            show: true,
          },
          {
            id: 'interestPaymentName',
            title: 'Interest payment - name',
            colType: 'text',
            show: true,
          },
          {
            id: 'interestPayment',
            title: 'Interest payment',
            colType: 'text',
            show: true,
          },
          {
            id: 'interestPaymentSwift',
            title: 'Interest payment - swift',
            colType: 'text',
            show: true,
          },
          {
            id: 'principalPaymentName',
            title: 'Principal payment - name',
            colType: 'text',
            show: true,
          },
          {
            id: 'principalPayment',
            title: 'Principal payment',
            colType: 'text',
            show: true,
          },
          {
            id: 'principalPaymentSwift',
            title: 'Principal payment - swift',
            colType: 'text',
            show: true,
          },
          {
            id: 'grossInterest',
            title: 'Gross Interest',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'tax',
            title: 'Tax',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'netInterest',
            title: 'Net Interest',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'email',
            title: 'Email',
            colType: 'text',
            show: true,
          },
          {
            id: 'phone',
            title: 'Phone',
            colType: 'text',
            show: true,
          },
          {
            id: 'middleName',
            title: 'Middle Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'birthDate',
            title: 'Birth Date',
            colType: 'datetime',
            show: true,
          },
          {
            id: 'identificationNo',
            title: 'Identification no.',
            colType: 'text',
            show: true,
          },
          {
            id: 'documentType',
            title: 'Document Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'documentNo',
            title: 'Document no.',
            colType: 'text',
            show: true,
          },
          {
            id: 'documentExpiryDate',
            title: 'Document Expiry Date',
            colType: 'datetime',
            show: true,
          },
          {
            id: 'documentExpiryDateIndefinitely',
            title: 'Document Date - Indefinitely',
            colType: 'boolean',
            show: true,
          },
          {
            id: 'fathersName',
            title: 'Fathers Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'mothersName',
            title: 'Mothers Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'residentialAddress',
            title: 'Residential Address',
            colType: 'text',
            show: true,
          },
          {
            id: 'mailingAddress',
            title: 'Mailing Address',
            colType: 'text',
            show: true,
          },
          {
            id: 'nationality',
            title: 'Nationality',
            colType: 'text',
            show: true,
          },
          {
            id: 'taxResidence',
            title: 'Tax Residence',
            colType: 'text',
            show: true,
          },
          // 
          {
            id: 'privacyPolicyAndTermsAndConditions',
            title: 'Privacy Policy And Terms And Conditions',
            colType: 'boolean',
            show: true,
          },
          {
            id: 'privacyPolicyAndTermsAndConditionsDateAndTime',
            title: 'Privacy Policy And Terms And Conditions - date and time',
            colType: 'datetime',
            withTime: true,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'privacyPolicyAndTermsAndConditionsDateAndTime',
                  withTime: true,
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'privacyPolicyAndTermsAndConditionsDateAndTimeYear',
                    title: 'Privacy Policy And Terms And Conditions - date and time (year)',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'privacyPolicyAndTermsAndConditionsDateAndTimeMonth',
                    title: 'Privacy Policy And Terms And Conditions - date and time (month)',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'privacyPolicyAndTermsAndConditionsDateAndTimeYear',
            title: 'Privacy Policy And Terms And Conditions - date and time (year)',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'privacyPolicyAndTermsAndConditionsDateAndTimeMonth',
            title: 'Privacy Policy And Terms And Conditions - date and time (month)',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'marketingConsent',
            title: 'Marketing Consent',
            colType: 'boolean',
            show: true,
          },
          {
            id: 'marketingConsentDateAndTime',
            title: 'Marketing Consent - date and time',
            colType: 'datetime',
            withTime: true,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'marketingConsentDateAndTime',
                  withTime: true,
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'marketingConsentDateAndTimeYear',
                    title: 'Marketing Consent - date and time (year)',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'marketingConsentDateAndTimeMonth',
                    title: 'Marketing Consent - date and time (month)',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'marketingConsentDateAndTimeYear',
            title: 'Marketing Consent - date and time (year)',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'marketingConsentDateAndTimeMonth',
            title: 'Marketing Consent - date and time (month)',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'telecommunicationsConsent',
            title: 'Telecommunications Consent',
            colType: 'boolean',
            show: true,
          },
          {
            id: 'telecommunicationsConsentDateAndTime',
            title: 'Telecommunications Consent - date and time',
            colType: 'datetime',
            withTime: true,
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'telecommunicationsConsentDateAndTime',
                  withTime: true,
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'telecommunicationsConsentDateAndTimeYear',
                    title: 'Telecommunications Consent - date and time (year)',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'telecommunicationsConsentDateAndTimeMonth',
                    title: 'Telecommunications Consent - date and time (month)',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'telecommunicationsConsentDateAndTimeYear',
            title: 'Telecommunications Consent - date and time (year)',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'telecommunicationsConsentDateAndTimeMonth',
            title: 'Telecommunications Consent - date and time (month)',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
        ]
        break;
      case 'Brokers':
        columns = [
          {
            id: 'firstName',
            title: 'First Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'surname',
            title: 'surname',
            colType: 'text',
            show: true,
          },
          {
            id: 'email',
            title: 'Email',
            colType: 'text',
            show: true,
          },
          {
            id: 'phoneNumber',
            title: 'Phone Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'middleName',
            title: 'Middle Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'externalId',
            title: 'External ID',
            colType: 'text',
            show: true,
          },
          {
            id: 'status',
            title: 'Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'registrationDate',
            title: 'Registration Date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'registrationDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'registrationDateYear',
                    title: 'Registration Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'registrationDateMonth',
                    title: 'Registration Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'registrationDateYear',
            title: 'Registration Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'registrationDateMonth',
            title: 'Registration Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'network',
            title: 'Network',
            colType: 'text',
            show: true,
          },
          {
            id: 'officeManagerFirstName',
            title: 'Office Manager First Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'officeManagerSurname',
            title: 'Office Manager Surname',
            colType: 'text',
            show: true,
          },
          {
            id: 'officeManagerEmail',
            title: 'Office Manager Email',
            colType: 'text',
            show: true,
          },
          {
            id: 'officeManagerPhoneNumber',
            title: 'Office Manager Phone Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'officeManagerMiddleName',
            title: 'Office Manager Middle Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'officeManagerExternalId',
            title: 'Office Manager External ID',
            colType: 'text',
            show: true,
          },
          {
            id: 'officeManagerStatus',
            title: 'Office Manager Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'coordinatorFirstName',
            title: 'Coordinator First Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'coordinatorSurname',
            title: 'Coordinator Surname',
            colType: 'text',
            show: true,
          },
          {
            id: 'coordinatorEmail',
            title: 'Coordinator Email',
            colType: 'text',
            show: true,
          },
          {
            id: 'coordinatorPhoneNumber',
            title: 'Coordinator Phone Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'coordinatorMiddleName',
            title: 'Coordinator Middle Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'coordinatorExternalId',
            title: 'Coordinator External ID',
            colType: 'text',
            show: true,
          },
          {
            id: 'coordinatorStatus',
            title: 'Coordinator Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'directorFirstName',
            title: 'Director First Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'directorSurname',
            title: 'Director Surname',
            colType: 'text',
            show: true,
          },
          {
            id: 'directorEmail',
            title: 'Director Email',
            colType: 'text',
            show: true,
          },
          {
            id: 'directorPhoneNumber',
            title: 'Director Phone Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'directorMiddleName',
            title: 'Director Middle Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'directorExternalId',
            title: 'Director External ID',
            colType: 'text',
            show: true,
          },
          {
            id: 'directorStatus',
            title: 'Director Status',
            colType: 'text',
            show: true,
          },
        ]
        break;
      case 'Interest':
        columns = [
          {
            id: 'managingLegalEntity',
            title: 'Managing Legal Entity',
            colType: 'text',
            show: true,
          },
          {
            id: 'legalEntity',
            title: 'Legal Entity',
            colType: 'text',
            show: true,
          },
          {
            id: 'investor',
            title: 'Investor',
            colType: 'text',
            show: true,
          },
          {
            id: 'productType',
            title: 'Product Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'productCode',
            title: 'Product Code',
            colType: 'text',
            show: true,
          },
          {
            id: 'interestAmountGross',
            title: 'Gross Interest',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'interestTax',
            title: 'Tax Interest',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'interestAmountNet',
            title: 'Net Interest',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'currency',
            title: 'Currency',
            colType: 'text',
            show: true,
          },
          {
            id: 'interestPeriodFrom',
            title: 'Interest Period From',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'interestPeriodFrom'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'interestPeriodFromYear',
                    title: 'Interest Period From Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'interestPeriodFromMonth',
                    title: 'Interest Period From Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'interestPeriodFromYear',
            title: 'Interest Period From Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'interestPeriodFromMonth',
            title: 'Interest Period From Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'interestPeriodTo',
            title: 'Interest Period To',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'interestPeriodTo'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'interestPeriodToYear',
                    title: 'Interest Period To Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'interestPeriodToMonth',
                    title: 'Interest Period To Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'interestPeriodToYear',
            title: 'Interest Period To Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'interestPeriodToMonth',
            title: 'Interest Period To Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'repaymentDate',
            title: 'Repayment Date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'repaymentDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'repaymentDateYear',
                    title: 'Repayment Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'repaymentDateMonth',
                    title: 'Repayment Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'repaymentDateYear',
            title: 'Repayment Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'repaymentDateMonth',
            title: 'Repayment Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentType',
            title: 'Payment Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'paymentStatus',
            title: 'Payment Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'paymentBookDate',
            title: 'Payment Book Date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'paymentBookDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'paymentBookDateYear',
                    title: 'Payment Book Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'paymentBookDateMonth',
                    title: 'Payment Book Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'paymentBookDateYear',
            title: 'Payment Book Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'paymentBookDateMonth',
            title: 'Payment Book Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'description',
            title: 'Description',
            colType: 'text',
            show: true,
          },
          {
            id: 'bankAccount',
            title: 'Bank Account',
            colType: 'text',
            show: true,
          },
        ]
        break;
      case 'Portfolio Confirmation':
        columns = [
          {
            id: 'type',
            title: 'Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountLevel1',
            title: 'Account lvl 1',
            colType: 'text',
            show: true,
          },
          {
            id: 'lastAccount',
            title: 'Last Account',
            colType: 'text',
            show: true,
          },
          {
            id: 'numberOfShares',
            title: 'Number of shares',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'totalAmount',
            title: 'Total amount',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'investmentLoanCapital',
            title: 'Investment/Loan Capital',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
          {
            id: 'writeOffsInterestsCommission',
            title: 'Write-offs/Interest/Commission',
            colType: 'number',
            format: {
              value: null,
              options: [],
            },
            show: true,
          },
        ]
        break;
      case 'Fundraising Dashboard':
        columns = [
          {
            id: 'campaignStatus',
            title: 'Campaign status',
            colType: 'text',
            show: true,
          },
          {
            id: 'campaignName',
            title: 'Campaign name',
            colType: 'text',
            show: true,
          },
          {
            id: 'campaignValue',
            title: 'Campaign value',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'campaignCurrency',
            title: 'Campaign currency',
            colType: 'text',
            show: true,
          },
          {
            id: 'issuer',
            title: 'Issuer',
            colType: 'text',
            show: true,
          },
          {
            id: 'investmentCurrency',
            title: 'Investment currency',
            colType: 'text',
            show: true,
          },
          {
            id: 'signedPaidAmount',
            title: 'Signed paid amount',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'signedRemainingAmount',
            title: 'Signed remaining amount',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'notSignedRemainingAmount',
            title: 'Not signed remaining amount',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'signedFullyPaidCount',
            title: 'Signed fully paid count',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
          },
          {
            id: 'signedPartiallyPaidCount',
            title: 'Signed partially paid count',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
          },
          {
            id: 'signedUnpaidCount',
            title: 'Signed unpaid count',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
          },
          {
            id: 'notSignedCount',
            title: 'Not signed count',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
          },
        ]
        break;
      default:
        columns = [
          {
            id: 'reportNumber',
            title: 'Report Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'accountNumber',
            title: 'Account Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'number',
            title: 'Number',
            colType: 'number',
            format: {
              value: false,
              options: [],
            },
            show: true,
          },
          {
            id: 'counterpartyType',
            title: 'Counterparty Type',
            colType: 'text',
            show: true,
          },
          {
            id: 'counterpartyName',
            title: 'Counterparty Name',
            colType: 'text',
            show: true,
          },
          {
            id: 'counterpartyAddress',
            title: 'Counterparty Address',
            colType: 'text',
            show: true,
          },
          {
            id: 'counterpartyAccountNumber',
            title: 'Counterparty Account Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'transactionNumber',
            title: 'Transaction Number',
            colType: 'text',
            show: true,
          },
          {
            id: 'transactionDate',
            title: 'Transaction Date',
            colType: 'datetime',
            format: {
              value: 'date',
              options: [
                {
                  value: 'date',
                  label: 'Full Date',
                  key: 'transactionDate'
                },
                {
                  value: 'year',
                  label: 'Year',
                  column: {
                    id: 'transactionDateYear',
                    title: 'Transaction Date Year',
                    colType: 'number',
                    format: {
                      value: 'integer',
                      options: [],
                    },
                    show: true,
                  }
                },
                {
                  value: 'month',
                  label: 'Month',
                  column: {
                    id: 'transactionDateMonth',
                    title: 'Transaction Date Month',
                    colType: 'number',
                    format: {
                      value: 'leadingZero',
                      options: [],
                    },
                    show: true,
                  },
                }
              ]
            },
            show: true,
          },
          {
            id: 'transactionDateYear',
            title: 'Transaction Date Year',
            colType: 'number',
            format: {
              value: 'integer',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'transactionDateMonth',
            title: 'Transaction Date Month',
            colType: 'number',
            format: {
              value: 'leadingZero',
              options: [],
            },
            show: true,
            readOnly: true,
          },
          {
            id: 'transactionCcy',
            title: 'Transaction Currency',
            colType: 'text',
            show: true,
          },
          {
            id: 'transactionSettlementStatus',
            title: 'Transaction Status',
            colType: 'text',
            show: true,
          },
          {
            id: 'description',
            title: 'Description',
            colType: 'text',
            show: true,
          },
          {
            id: 'ibanFormat',
            title: 'IBAN',
            colType: 'text',
            show: true,
          },
          {
            id: 'inflowCcy',
            title: 'Inflow Currency',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'inflowPLN',
            title: 'Inflow PLN',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'outflowCcy',
            title: 'Outflow Currency',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
          {
            id: 'outflowPLN',
            title: 'Outflow PLN',
            colType: 'number',
            format: {
              value: null, // 0,00
              options: [],
            },
            show: true,
          },
        ]
        break;
    }
    
    const schema: any = {
      columns,
      sorting: {
        order: 'desc',
        orderBy: null
      },
      toolbar: {
        displaySelectionDetails: true,
        manageFields: true,
        exportToCSV: true,
      },
      view: {
        enabled: ['grid', 'pivot'],
        type: 'grid'
      }
    }

    return schema
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get hasAccountingProgram() {
    return !!(this.activeUserData?.accountingProgram)
  }

  get userContexts() {
    return this.$store.getters['genprox/userContexts']
  }

  get isPersonalAccount() {
    return !!(this.activeUserData?.context?.name?.toLowerCase() === 'personal account')
  }

  get availableContexts() {
    return this.userContexts?.filter((el: any) => el?.name?.toLowerCase() !== 'personal account')
  }

  getReportName(type: string) {
    switch(type?.toLowerCase()) {
      case 'bank-transactions':
        return 'Bank Transactions'
      case 'p-l-transactions':
        return 'P&L Transactions'
      case 'portfolio-capital':
        return 'Portfolio & Capital'
      case 'portfolio-confirmation':
        return 'Portfolio Confirmation'
      case 'all-entries-transactions':
        return 'All entries Transactions'
      case 'receivables-payables':
        return 'Receivables & Payables'
      case 'fundraising-analytics-debt':
        return 'Fundraising'
      case 'fundraising-dashboard':
        return 'Fundraising Dashboard'
      case 'broker':
        return 'Brokers'
      case 'interests':
        return 'Interest'
      default:
        return type
    }
  }

  getReportAdditionalFields(type: string) {
    switch(type?.toLowerCase()) {
      case 'bank-transactions':
      case 'p-l-transactions':
      case 'portfolio-capital':
      case 'portfolio-confirmation':
      case 'all-entries-transactions':
        return {
          type: 'dateFrom',
          period: moment().utc().format('YYYY')
        }
      default:
        return {}
    }
  }

  goBackHandler() {
    const linkParentPath = this.$route?.fullPath?.includes('/fund-manager') ? 'fund-manager' : this.$route?.fullPath?.includes('/company') ? 'company' : 'fund'
    this.$router.push({ path: `/${linkParentPath}/analytics` })
    this.selectedReport = null
    this.tableData = []
  }

  async openReport(report: any) {
    if (report?.action) {
      const linkParentPath = this.$route?.fullPath?.includes('/fund-manager') ? 'fund-manager' : this.$route?.fullPath?.includes('/company') ? 'company' : 'fund'
      let action = report?.action
      if (report?.type && report?.period) {
        action = `${action}?type=${report?.type}&period=${report?.period}`
      }
      this.$router.push({ path: `/${linkParentPath}/analytics/${action}` })
      this.loadReport(report)
    }
  }

  async loadReport(report: any) {
    if (report.action) {
      this.isLoading = true
  
      try {
        const payload = { action: report?.action, type: report?.type, period: report?.period };
        const tableData = await this.$store.dispatch('genprox/getFinancialReporting', payload)
        this.tableData = tableData
        this.selectedReport = report.name
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
  
      this.isLoading = false
    } else {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: 'Report not configured yet'
      })
    }
  }

  async created() {
    let reports: any[] = await this.$store.dispatch('genprox/getFinancialReportingTypes') || []

    reports = reports?.filter((report: any) => !this.hasAccountingProgram ? report?.type != 'optima' : true)?.sort((a: any) => a?.type == 'optima' ? 1 : 2)?.map((report: any, i: number) => {
      return {
        id: i + 1,
        name: this.getReportName(report?.report),
        action: report?.report,
        ...this.getReportAdditionalFields(report?.report)
      }
    })

    this.reports = reports || []

    if (this.$route?.params?.reportName) {
      const foundReport = this.reportList?.find((report: any) => report?.action === this.$route?.params?.reportName)

      if (foundReport) {
        if (this.$route?.query?.type) {
          this.$set(foundReport, 'type', this.$route?.query?.type)
        }

        if (this.$route?.query?.period) {
          this.$set(foundReport, 'period', this.$route?.query?.period)
        }

        this.loadReport(foundReport)
      } else {
        const linkParentPath = this.$route?.fullPath?.includes('/fund-manager') ? 'fund-manager' : this.$route?.fullPath?.includes('/company') ? 'company' : 'fund'
        this.$router.push({ path: `/${linkParentPath}/analytics/` })
        setTimeout(() => {
          this.$notify({
            duration: 3500,
            type: 'error',
            title: 'Error',
            text: `It looks like report doesn't exist.`
          })
        }, 50)
      }
    }
    // if (this.isPersonalAccount) {
    //   if (this.availableContexts?.length === 1) {
    //     this.$root.$emit('setUserContext', this.availableContexts[0], '/fund/analytics')
    //   }
    // }

    // if (!this.hasAccountingProgram) {
    //   this.$notify({
    //     duration: 3500,
    //     type: 'error',
    //     title: 'Error',
    //     text: 'You need to configure accounting program in order to use this feature'
    //   })
    // }
  }

  @Watch('$route.path') onRoutePathChange() {
    if (!this.$route?.params?.reportName) {
      this.selectedReport = null
      this.tableData = []
    } else {
      if (this.$route?.params?.reportName) {
        const foundReport = this.reportList?.find((report: any) => report?.action === this.$route?.params?.reportName)

        if (foundReport) {
          if (this.$route?.query?.type) {
            this.$set(foundReport, 'type', this.$route?.query?.type)
          }

          if (this.$route?.query?.period) {
            this.$set(foundReport, 'period', this.$route?.query?.period)
          }

          this.loadReport(foundReport)
        } else {
          const linkParentPath = this.$route?.fullPath?.includes('/fund-manager') ? 'fund-manager' : this.$route?.fullPath?.includes('/company') ? 'company' : 'fund'
          this.$router.push({ path: `/${linkParentPath}/analytics/` })
          setTimeout(() => {
            this.$notify({
              duration: 3500,
              type: 'error',
              title: 'Error',
              text: `It looks like report doesn't exist.`
            })
          }, 50)
        }
      }
    }
  }
}
